import React from "react";
import { EnumShippingType } from "~/data/product/useGetEstimateDelivery";
import { Country } from "~/services/countries";
import {
  Product,
  ProductCustomizeSettings,
  ProductSide,
  ProductVariant,
} from "~/services/product";

interface ProductDetailContextValues {
  product: Product;
  selectedShippingMethod: EnumShippingType;
  setSelectedShippingMethod: (value: EnumShippingType) => void;
  variant: ProductVariant;
  setVariant: (value: ProductVariant) => void;
  quantity: number;
  setQuantity: (quantity: number) => void;
}

export const ProductDetailContext =
  React.createContext<ProductDetailContextValues>({
    product: {} as Product,
    selectedShippingMethod: EnumShippingType.DEFAULT,
    setSelectedShippingMethod: () => {},
    variant: {} as ProductVariant,
    setVariant: () => {},
    quantity: 1,
    setQuantity: () => {},
  });

interface ProductCustomEditorContextValues {
  customizeSettings: ProductCustomizeSettings;
  setCustomizeSettings: (property: string, value: any) => void;
  product: Product;
  selectedSide: ProductSide;
  setSelectedSide: (value: ProductSide) => void;
  variant: ProductVariant;
  setVariant: (value: ProductVariant) => void;
}

export const ProductCustomEditorContext =
  React.createContext<ProductCustomEditorContextValues>({
    product: {} as Product,
    customizeSettings: {} as ProductCustomizeSettings,
    setCustomizeSettings: () => {},
    selectedSide: ProductSide.FRONT,
    setSelectedSide: () => {},
    variant: {} as ProductVariant,
    setVariant: () => {},
  });

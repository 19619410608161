import { gql } from "@apollo/client";

export const QUERY_GET_PRODUCT_DETAIL_SSR = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        id
        sku
        name
        swatch_attribute
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              sku
            }
          }
        }
      }
    }
  }
`;

export const QUERY_PRODUCT_QUESTIONS_SSR = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        productQuestions(page_size: 5, current_page: 1) {
          items {
            questionId
            question
            answers(page_size: 1, current_page: 1) {
              items {
                answer
              }
            }
          }
        }
      }
    }
  }
`;
export const QUERY_GET_META_PRODUCT_DETAIL_BY_SLUG = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        klaviyo {
          script
        }

        id
        name
        meta_title
        meta_keyword
        meta_description
        swatch_attribute
        name
        sku
        url_key
        thumbnail {
          url
          label
        }
        media_gallery {
          url
          label
        }
        categories {
          id
          url_key
          name
          # breadcrumbs
          canonical_url
          display_mode
          url_path
          path
          path_in_store
          position
          url_suffix
          # children
          children_count
          level
          breadcrumbs {
            category_id
            category_level
            category_name
            category_uid
            category_url_key
            category_url_path
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            final_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
          }
        }
        __typename
        productQuestions(page_size: 5, current_page: 1) {
          items {
            questionId
            question
            answers(page_size: 1, current_page: 1) {
              items {
                answer
              }
            }
          }
        }
        reviews(pageSize: 4, currentPage: 1) {
          summary
          summary_detail
          items {
            nickname
            average_rating
            created_at
            summary
            text
          }
          total_count
        }
        description {
          html
        }
        media_gallery {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            uid
            id
            label
            position
            use_default
            attribute_code
            values {
              uid
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              sku
              name
              thumbnail {
                url
                label
              }
              media_gallery {
                url
                label
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const QUERY_PRODUCT_DETAIL_BY_SKU = gql`
  query product($param: String!) {
    products(filter: { sku: { eq: $param } }) {
      items {
        id
        name
        sku
        url_key
        categories {
          id
          url_key
          name
          # breadcrumbs
          canonical_url
          display_mode
          url_path
          path
          path_in_store
          position
          url_suffix
          # children
          children_count
          level
          breadcrumbs {
            category_id
            category_level
            category_name
            category_uid
            category_url_key
            category_url_path
          }
        }
      }
    }
  }
`;

export const QUERY_GET_ESTIMATE_SHIPPINGS = gql`
  query EstimateShipping($product_sku: String!, $country_code: String!) {
    EstimateShipping(sku: $product_sku, country_code: $country_code) {
      is_freeshipping
      estimate_shipping {
        type
        enable
        placed_ships_from
        placed_ships_to
        ships_delivered_from
        ships_delivered_to
        carrier_code
        shipping_cost
      }
    }
  }
`;
export const QUERY_GET_CONFIGURATIONS_PRODUCT_DETAIL = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        id
        sku
        ... on ConfigurableProduct {
          configurable_options {
            uid
            id
            label
            position
            use_default
            attribute_code
            values {
              uid
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;

export const QUERY_GET_VARIANT_PRODUCT_DETAIL = gql`
  query product($param: String!) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        id
        sku
        ... on ConfigurableProduct {
          variants {
            product {
              id
              name
              sku
              catalogRule {
                expire_date
                expire_seconds
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;
export const QUERY_GET_REVIEW_PRODUCT_DETAIL = gql`
  query product($param: String!, $pageSize: Int = 4, $currentPage: Int = 1) {
    products(filter: { url_key: { eq: $param } }) {
      items {
        id
        sku
        reviews(pageSize: $pageSize, currentPage: $currentPage) {
          items {
            images
            nickname
            average_rating
            created_at
            summary
            text
          }
          total_count
          summary
          page_info {
            page_size
            current_page
          }
        }
      }
    }
  }
`;
export const ADD_PRODUCT_REVIEW = gql`
  mutation createProductReview(
    $sku: String!
    $nickname: String!
    $summary: String!
    $text: String!
    $ratingsId: String!
    $ratingsValueId: String!
    $images: [String]
  ) {
    createProductReview(
      input: {
        sku: $sku
        nickname: $nickname
        summary: $summary
        text: $text
        ratings: [{ id: $ratingsId, value_id: $ratingsValueId }]
        images: $images
      }
    ) {
      review {
        nickname
        summary
        text
        average_rating
        ratings_breakdown {
          name
          value
        }
      }
    }
  }
`;

export const GET_REVIEW_META_DATA = gql`
  query productReviewRatingsMetadata {
    productReviewRatingsMetadata {
      items {
        id
        name
        values {
          value_id
          value
        }
      }
    }
  }
`;
export const CREATE_QUESTION_BY_SKU = gql`
  mutation createQuestion(
    $sku: String!
    $question: String!
    $customerName: String = ""
    $customerEmail: String = ""
  ) {
    createQuestion(
      input: {
        sku: $sku
        question: $question
        customerName: $customerName
        customerEmail: $customerEmail
      }
    ) {
      questionId
      question
      customerName
      customerEmail
      rule
    }
  }
`;

export const GET_QUESTION_PRODUCT_BY_SKU = gql`
  query productQuestions(
    $sku: String!
    $keyword: String = ""
    $sort: String = "newest"
    $page_size: Int = 4
    $current_page: Int = 1
  ) {
    productQuestions(
      sku: $sku
      keyword: $keyword
      sort: $sort
      page_size: $page_size
      current_page: $current_page
    ) {
      items {
        questionId
        question
        customerName
        customerEmail
        rule
        like
        dislike
        createdAt
        answers(page_size: $page_size, current_page: $current_page) {
          items {
            answerId
            answer
            customerName
            customerEmail
            rule
            like
            dislike
            createdAt
          }
          total
        }
      }
      total
    }
  }
`;
export const CREATE_QUESTION_VOTE = gql`
  mutation createQuestionVote(
    $questionId: Int!
    $vote: String!
    $cartId: String = ""
  ) {
    createQuestionVote(
      input: { questionId: $questionId, vote: $vote, cartId: $cartId }
    ) {
      questionId
      question
      customerName
      customerEmail
      rule
      like
      dislike
      createdAt
    }
  }
`;

export const CREATE_ANSWER_VOTING = gql`
  mutation createAnswerVote(
    $answerId: Int!
    $vote: String!
    $cartId: String = ""
  ) {
    createAnswerVote(
      input: { answerId: $answerId, vote: $vote, cartId: $cartId }
    ) {
      answer
      customerName
      customerEmail
      rule
      like
      dislike
    }
  }
`;
export const CREATE_ANSWER_BY_QUESTION_ID = gql`
  mutation createAnswer(
    $questionId: Int!
    $answer: String!
    $customerName: String = ""
    $customerEmail: String = ""
  ) {
    createAnswer(
      input: {
        questionId: $questionId
        answer: $answer
        customerName: $customerName
        customerEmail: $customerEmail
      }
    ) {
      answerId
      answer
      customerName
      customerEmail
      rule
      like
      dislike
      createdAt
    }
  }
`;

export const QUERY_GET_RELATED_PRODUCT = gql`
  query relatedProducts($sku: String!, $code: String!) {
    relatedProduct(sku: $sku, code: $code) {
      name
      # code
      items {
        name
        # catalogRule {
        #     expire_date
        #     expire_seconds
        # }
        sku
        url_key
        # price_range {
        #     minimum_price {
        #         discount {
        #             amount_off
        #             percent_off
        #         }
        #         regular_price {
        #             value
        #             currency
        #         }
        #         final_price {
        #             value
        #             currency
        #         }
        #     }
        # }
        thumbnail {
          url
          label
        }
        ... on ConfigurableProduct {
          configurable_options {
            uid
            id
            label
            position
            use_default
            attribute_code
            values {
              uid
              value_index
              label
            }
            product_id
          }
          variants {
            product {
              id
              sku
              name
              thumbnail {
                url
                label
              }
              media_gallery {
                url
                label
              }
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  final_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                }
              }
            }
            attributes {
              uid
              label
              code
              value_index
            }
          }
        }
      }
    }
  }
`;
export const QUERY_OPTIMISTIC_PRODUCTS_LIST = gql`
  query products(
    $pageSize: Int = 24
    $currentPage: Int = 1
    $filter: ProductAttributeFilterInput
    $sort: ProductAttributeSortInput
  ) {
    products(
      filter: $filter
      pageSize: $pageSize
      currentPage: $currentPage
      sort: $sort
    ) {
      items {
        name
        sku
        id
        url_key
        small_image {
          url
          label
        }
        thumbnail {
          url
        }
      }
      total_count
      page_info {
        page_size
        current_page
      }
    }
  }
`;
export const QUERY_SHIPPING_BY_COUNTRY = gql`
  query ShippingMethodByCountry($country_code: String!) {
    ShippingMethodByCountry(country_code: $country_code) {
      carrier
      title
      shipping_cost
    }
  }
`;
export const GET_SIZE_GUIDE = gql`
  query SizeGuide($sku: String!) {
    SizeGuide(sku: $sku) {
      category_master
      size_guide
      shipping_policy
    }
  }
`;

export const QUERY_RELATED_TRACKING = gql`
  query relatedTracking($sku: String!) {
    relatedTracking(sku: $sku)
  }
`;
export const QUERY_PRODUCT_INDEX = gql`
  query productIndex($sku: String!) {
    productIndex(sku: $sku) {
      last_24h_view_count
      last_24h_sale_count
      last_24h_in_cart_count
    }
  }
`;

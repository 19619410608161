import { useQuery } from '@apollo/client';
import _get from "lodash/get";
import { QUERY_GET_ESTIMATE_SHIPPINGS } from './gql';
import { EstimateDelivery } from './interfaces';
import dayjs from 'dayjs';

type Variables = {
  product_sku?: string
  country_code?:string
}
export interface IUseGetEstimateDelivery {
  variables: Variables;
  pageSize?: number;
  currentPage?: number;
}

export enum EnumShippingType {
  DEFAULT = 'default_estimate_shipping',
  FREE = 'free_shipping_estimate_shipping',
  STANDARD = 'standard_estimate_shipping',
  EXPRESS = 'express_estimate_shipping',
}

export type Step = {
  title: string;
  date: string;
  icon: string;
} 
export type ProgressShipping = {
  type:any
  carrier_code?: string;
  shipping_cost?: number;
  title: string;
  steps:Array<Step>
  shipsDeliveredFrom:any
  shipsDeliveredTo:any
}


const labels = {
  [EnumShippingType.DEFAULT]: "Default",
  [EnumShippingType.FREE]: 'Free Shipping',
  [EnumShippingType.STANDARD]: 'Standard',
  [EnumShippingType.EXPRESS]: 'Express',
}



export function useGetEstimateDelivery({ variables }: IUseGetEstimateDelivery) {
  const { data, ...rest } = useQuery<EstimateDelivery>(QUERY_GET_ESTIMATE_SHIPPINGS, {
    variables
  });
  const shippingData = _get(data, "EstimateShipping", {} as EstimateDelivery)
  const progress = shippingData?.estimate_shipping?.map((s) => {
    const { placed_ships_from, placed_ships_to, ships_delivered_from, ships_delivered_to } = s
    const title = labels[s?.type as EnumShippingType]
    const currentDate = dayjs().format('ddd, MMM DD');
    const placedShipsFrom = dayjs().add(Number(placed_ships_from), 'day')
    const placedShipsTo = dayjs().add(Number(placed_ships_to), 'day')
    const shipsDeliveredFrom = dayjs().add(Number(ships_delivered_from), 'day')
    const shipsDeliveredTo = dayjs().add(Number(ships_delivered_to), 'day')

    const progressData = {
      title,
      steps: [
        { title: "Order Placed", date: currentDate, icon: "bag-tick" },
        { title: "Delivering", date: `${placedShipsFrom.format('MMM DD')}-${placedShipsTo.format('DD')}`, icon: "truck-fast" },
        { title: "Received", date: `${shipsDeliveredFrom.format('MMM DD')}-${shipsDeliveredTo.format('DD')}`, icon: "box" },
      ],
      type: s?.type,
      currentDate,
      placedShipsFrom,
      placedShipsTo,
      shipsDeliveredFrom,
      shipsDeliveredTo,
      carrier_code: s?.carrier_code,
      shipping_cost: s?.shipping_cost

    }

    return progressData as ProgressShipping
  }) || []

  const dataTransform = {
    ...shippingData,
    progress
  }
  
  let mimumdate =  dataTransform?.progress?.[0]
  let maximumdate =  dataTransform?.progress?.[1]
  dataTransform?.progress?.forEach((shippingItem:any) => {
    if(mimumdate?.shipsDeliveredFrom && shippingItem?.shipsDeliveredFrom?.isBefore(mimumdate?.shipsDeliveredFrom)){
      mimumdate = shippingItem
    }else if(maximumdate?.shipsDeliveredFrom && shippingItem?.shipsDeliveredFrom.isAfter(maximumdate?.shipsDeliveredFrom) ){
      maximumdate = shippingItem
    }
  })
  return {
    data: dataTransform,
    mimumdate,
    maximumdate,
    ...rest
  }
}
import { atom } from "recoil";
import { ProductAtomProps } from "./product.type";

const Product = atom<Partial<ProductAtomProps>>({
  key: "Product",
  default: {
    hoverSwatchOptions: undefined,
  },
  //   effects: [localStorageEffect("order")],
});

export default { Product };

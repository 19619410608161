export type ProductAtomProps = {
  hoverSwatchOptions?: {
    thumbnail: string;
    label: string;
  };
};
export enum ProductSide {
  FRONT = "front",
  BACK = "back",
}

export type ProductLayout = "1x1" | "3x3";

export interface TextLayout {
  x: string;
  y: string;
  width: string;
  height: string;
  max_length: number;
}

export interface ImageLayout {
  x: string;
  y: string;
  width: string;
  height: string;
}

export interface ProductCategory {
  id: string;
  name: string;
  url_key: string;
  position: number;
  canonical_url?: string;
  display_mode?: string;
  url_path?: string;
  path?: string;
  url_suffix?: string;
  children_count?: string;
  level: number;
  breadcrumbs: {
    category_id: any;
    category_level: any;
    category_name: any;
    category_uid: any;
    category_url_key: any;
    category_url_path: any;
  }[];
}

export interface ProductDiscount {
  amount_off: number;
  percent_off: number;
}

export interface ProductPrice {
  value: number;
  currency: string;
  symbol: string;
}

export interface ProductPriceSet {
  discount?: ProductDiscount;
  regular_price: ProductPrice;
  final_price: ProductPrice;
}

export interface ProductPriceRange {
  minimum_price: ProductPriceSet;
  maximum_price?: ProductPriceSet;
}

export interface ProductMedia {
  url: string;
  label: string;
}

export interface ProductConfigurableOption {
  id: string;
  label: string;
  position: number;
  use_default: boolean;
  attribute_code: string;
  values: {
    value_index: number;
    label: string;
    url?: string;
    disabled?: boolean;
  }[];
  product_id: string;
}

export interface ProductVariant {
  product: {
    id: number;
    name: string;
    sku: string;
    price_range: Partial<ProductPriceRange>;
    image?: ProductMedia;
    thumbnail?: ProductMedia;
    small_image?: ProductMedia;
    media_gallery?: ProductMedia[];
  };
  attributes: {
    uid: string;
    label: string;
    code: string;
    url?: string;
    value_index: number;
    [x: string]: any;
  }[];
}

export interface Product {
  id: string;
  name: string;
  sku?: string;
  categories: ProductCategory[];
  url_key?: string;
  swatch_attribute?: string;
  rating_summary?: number;
  review_count?: number;
  price_range?: ProductPriceRange;
  image: ProductMedia;
  small_image?: ProductMedia;
  media_gallery?: ProductMedia;
  configurable_options?: ProductConfigurableOption[];
  variants?: ProductVariant[];
  slug?: string;
  images: string[];
  type: "simple" | "personalized" | "custom";
  customize_layout?: Partial<Record<ProductSide, ProductLayout>> & {
    safe_area?: {
      width: string;
      height: string;
      x: string;
      y: string;
    };
  };
  customize_setting?: any;
  text_layout?: Partial<Record<ProductSide, TextLayout>>;
  image_layout?: Partial<Record<ProductSide, ImageLayout>>;
  badges?: string[];

  quantity?: number;

  short_description?: string;
  description?: {
    html: string;
  };
}

export interface ProductCustomizeSettings {
  color?: string;
  size?: string;
  front_patterns?: any;
  back_patterns?: any;
  front?: any;
  back?: any;
  text?: any;
}
